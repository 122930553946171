import React from "react";

import { Grid, Card, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LineChart } from "@mui/x-charts/LineChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { CheckCircleOutlined } from "@mui/icons-material";
import theme from "../theme";
import { Colors } from "../config/default";
import useMediaQuery from "@mui/material/useMediaQuery";

function LineGraph({
  average,
  averageVal,
  averageHeading,
  target,
  dataSet,
  xAxisType,
  yAxisType,
  xAxisKey,
  yAxisKey,
  dataKey,
  midScreen,
}) {
  const mediumScreen = useMediaQuery(
    "(min-width:900px) and (max-width:1110px)"
  );
  const smallScreen = useMediaQuery("(max-width:600px)");

  return (
    <ThemeProvider theme={theme}>
      <Grid item xs={12} md={5.8} sx={{ height: { xl: "max-content" } }}>
        <Card
          sx={{
            marginTop: "1rem",

            height: { xs: "26em", md: "23rem", xl: "28rem" },
            borderRadius: "20px",
            padding: "1rem 1rem 1rem 0rem",
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
              paddingLeft: "1rem",
              flexWrap: "wrap",
              gap: "1em",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleOutlined
                sx={{ color: Colors.TEXTGREY, marginRight: ".5em" }}
              />
              <Typography sx={{ fontSize: ".8rem", color: Colors.TEXTGREY }}>
                {average}
              </Typography>
            </div>
            <div style={{ display: "flex" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  color: Colors.TEXTGREY,
                  marginRight: ".3rem",
                }}
              >
                {averageHeading}
              </Typography>
              <Typography sx={{ fontSize: "1rem", color: Colors.LIGHTBLUE }}>
                {averageVal}
              </Typography>
            </div>
          </Grid>

          <div style={{ display: "flex", width: "100%" }}>
            {!smallScreen ? (
              <p
                style={{
                  height: "2px",
                  transform: "rotate(270deg)",
                  marginTop: "10em",
                  color: Colors.LIGHTBLUE,
                  fontSize: "15px",
                }}
              >
                {target}
              </p>
            ) : (
              ""
            )}

            <LineChart
              sx={{
                color: Colors.TEXTGREY,
                "& .MuiLineElement-root": {
                  stroke: Colors.LIGHTBLUE,
                },
                [`.${axisClasses.label}`]: {
                  transform: "translate(0px, 10px)",
                  fill: `${Colors.LIGHTBLUE} !important`,
                },
              }}
              xAxis={[{ scaleType: "point", dataKey: "date" }]}
              dataset={dataSet}
              xAxisType={xAxisType}
              yAxisType={yAxisType}
              xAxisKey={xAxisKey}
              yAxisKey={yAxisKey}
              series={[
                {
                  dataKey: dataKey,
                  curve: "linear",
                  showMark: false,
                },
              ]}
              height={midScreen && mediumScreen ? 300 : 350}
            >
              {!smallScreen ? (
                <ChartsXAxis labelFontSize={14} label="Date" />
              ) : (
                ""
              )}
            </LineChart>
          </div>
        </Card>
      </Grid>
    </ThemeProvider>
  );
}

export default LineGraph;

import React from "react";

import { Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import DateRangePickerWithButtons from "./datePicker";
import theme from "../theme";

function HeaderSection({ setEndDate, setStartDate, startDate, endDate }) {
  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          borderBottom: { xs: "none", md: "2px solid #2A2A2A" },
          alignItems: { lg: "center" },
          justifyContent: "space-between",
          height: { xs: "max-content", lg: "5rem" },
          paddingX: { xs: "1em", lg: "2rem" },
        }}
      >
        <Grid
          xs={12}
          item
          sx={{
            display: "flex",
            height: { xs: "max-content", md: "3.3rem" },
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <DateRangePickerWithButtons
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default HeaderSection;

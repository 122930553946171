import React from "react";

import { Box } from "@mui/material";
import { Check } from "@mui/icons-material";

import { Colors } from "../config/default";
import BusinessAccountsList from "./businessAccountsList";

export default function AccountsInput({
  label,
  state,
  setState,
  data,
  setPermission,
  permission
}) {
  return (
    <div className="value-button">
      <Box className="box-style">
        <p className="paragraph-style">
          <Check
            sx={{
              fontSize: "1.5em",
              marginRight: ".5rem",
              color: state === true ? Colors.DARKBLUE : "",
            }}
            onClick={() => setState(!state)}
          />
          {label}
        </p>
      </Box>
      {data?.length > 0 && !data?.includes(null) ? (
        <div className="dropdown-wrapper">
          <BusinessAccountsList
            permission={permission}
            setPermission={setPermission}
            question=""
            data={data}
            color={Colors.TEXTGREY}
          />
        </div>
      ) : (
        <input
          className="input-field"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
        />
      )}
    </div>
  );
}
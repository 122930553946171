import React from "react";
import ReactDOM from "react-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "../src/redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastProvider } from "./toast/toastContext";
import CustomToaster from "./toast/customToaster";

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastProvider>
          <App />
          <CustomToaster />
        </ToastProvider>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();

import * as React from "react";

import { Box, Button, Modal } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Close } from "@mui/icons-material";

import { Colors } from "../config/default";
import ManualApprovalSelections from "./manualApprovalSelections";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: Colors.Canvas_BG,
  borderRadius: "20px",
  p: 3,
};

export default function MuiModal({
  text,
  btnHeight,
  show,
  icon,
  mails,
  setMails,
  apiData,
  marginBottom,
  variant,
  submitData,
  color,
  width,
  disable,
  setOpened,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (show === "manualUsers") {
      setMails([]);
    }
    if (show === "duplicateDocument") {
      setOpened(false);
    }
  };

  return (
    <div>
      {show === "manualUsers" ? (
        <KeyboardArrowDownIcon
          onClick={handleOpen}
          sx={{ position: "absolute", right: ".5em", top: ".3em" }}
        />
      ) : (
        <Button
          onClick={handleOpen}
          variant={variant ? variant : "outlined"}
          disabled={disable}
          sx={{
            textTransform: "unset !important",
            borderRadius: "10px",
            height: btnHeight,
            color: Colors.WHITE,
            width: width ? width : "",
            marginBottom: marginBottom ? marginBottom : "",
            borderColor: color ? color : Colors.DEEPBLUE,
            "&:hover": {
              borderColor: color ? color : Colors.DEEPBLUE,
            },
          }}
        >
          {icon ? icon : ""}
          {text}
        </Button>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: {
              xs: "90vw",
              md: "90vw",
              lg: "30vw",
            },
          }}
        >
          <Close
            sx={{
              color: Colors.TEXTGREY,
              top: "20px",
              right: "20px",
              cursor: "pointer",
              position: "absolute",
            }}
            onClick={handleClose}
          />
          {show === "manualUsers" && (
            <ManualApprovalSelections
              apiData={apiData}
              mails={mails}
              setMails={setMails}
              submitData={submitData}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}

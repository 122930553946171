import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import TopNav from './topNav';
import theme from '../theme';

function Layout({
  activeTab,
  setActiveTab,
  currentTabs,
  path,
  filteredData,
  recordExist,
  handleClear,
  show,
  buttonArray,
  submitData,
  id,
  comment,
  setComment,
  docName,
  padding,
  userOrVendor
}) {
  const user = useSelector((state) => state.signIn?.signIn?.cognitoRes?.idToken?.payload);

  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <TopNav
          padding={padding}
          username={user?.name}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          currentTabs={currentTabs}
          path={path}
          userRole={user?.given_name}
          filteredData={filteredData}
          recordExist={recordExist}
          handleClear={handleClear}
          show={show}
          buttonArray={buttonArray}
          submitData={submitData}
          id={id}
          comment={comment}
          setComment={setComment}
          docName={docName}
          userOrVendor={userOrVendor}
        />
      </Grid>
    </ThemeProvider>
  );
}

export default Layout;

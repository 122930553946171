import React, { useState } from "react";

import { Grid } from "@mui/material";

import Dashboard from "./dashboard";
import HeaderSection from "../components/headerSection";
import Layout from "../components/layout";
import { Colors } from "../config/default";

export default function MainDashboard() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  return (
    <div
      style={{
        backgroundColor: Colors.BG_BLACK,
        height: "100vh",
      }}
    >
      <Layout path="dashboard" padding={true} />
      <Grid sx={{ padding: "0px 16px" }}>
        <HeaderSection
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <Dashboard startDate={startDate} endDate={endDate} />
      </Grid>
    </div>
  );
}

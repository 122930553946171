import axios from "axios";
import { baseUrl } from "../constants/appConstants";
import { setHeaders } from "./common";

const BASE_URL = baseUrl();

export const getDocumentDetails = async (id, callSource) => {
  try {
    return await axios.get(
      BASE_URL +
        `api/v1/document/get/document/V2/${id}?callSource=${callSource}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const updateDocument = async (id, body) => {
  try {
    return await axios.put(
      BASE_URL + `api/v1/document/update/content/${id}`,
      body,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const sendEmailToVendor = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + "api/v1/email/send-email-to-vendor",
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const uploadCsv = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + "api/v1/user/update-user-gl-info",
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const matchPO = async (id, payload) => {
  try {
    return await axios.post(
      BASE_URL + `api/v1/integration/match-PurchaseOrder/${id}`,
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getInfo = async (name, headers) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/user/get-user?userName=${name}`,
      headers()
    );
  } catch (error) {
    return error;
  }
};

export const generateBill = async (payload) => {
  try {
    return await axios.post(
      BASE_URL + "api/v1/payment/create-payment-link",
      payload,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const getVendorsDocument = async (id) => {
  try {
    return await axios.get(
      BASE_URL + `api/v1/vendor/getDocumentById/${id}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

export const sendDataToCallBackApi = async (url, payload) => {
  try {
    return await axios.post(url, payload);
  } catch (error) {
    return error;
  }
};

export const getDashboardRecord = async (start, end, callSource) => {
  try {
    return await axios.get(
      BASE_URL +
        `api/v1/vendor/get-dashboard-analytics?startDate=${start}&endDate=${end}&callSource=${callSource}`,
      setHeaders()
    );
  } catch (error) {
    return error;
  }
};

import React from "react";
import { Colors } from "../config/default";
import { Grid, Typography } from "@mui/material";

function LandingPage() {
  return (
    <Grid
      container
      sx={{
        backgroundColor: Colors.BG_BLACK,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: Colors.WHITE,
      }}
    >
      <Typography variant="h3">Welcome to Human in the Loop!</Typography>
    </Grid>
  );
}

export default LandingPage;

import * as React from 'react';

import { InputLabel, FormControl, TextField, Autocomplete, Paper } from '@mui/material';

import { Colors } from '../config/default';

export default function BusinessAccountsList({ permission, setPermission, question, color, data }) {
  const handleChange = (event, value) => {
    setPermission(value);
  };

  const handleInputChange = (event, value) => {
    setPermission(value);
  };

  return (
    <div>
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-standard-label">{question}</InputLabel>
        <Autocomplete
          value={permission}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={data}
          freeSolo
          PaperComponent={({ children }) => (
            <Paper
              style={{
                backgroundColor: Colors.NAV_BLACK,
                color: Colors.TEXTGREY,
                borderRadius: '10px',
                padding: '0px 8px'
              }}>
              {children}
            </Paper>
          )}
          renderInput={(params) => (
            <TextField
              sx={{
                marginTop: { lg: '.7rem' }
              }}
              {...params}
              variant="standard"
              label=""
              InputProps={{
                ...params.InputProps,
                sx: {
                  color: color,
                  fontSize: '13px'
                }
              }}
            />
          )}
        />
      </FormControl>
    </div>
  );
}

/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { isEmpty } from "lodash";

import {
  Table,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  TableBody,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { Error } from "@mui/icons-material";

import { findMissingUtil } from "../constants/utils";
import { Colors } from "../config/default";
import { TooltipMessages } from "../constants/appConstants";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "white !important",
    borderBottom: ".5px  solid #676767",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "white !important",
    borderBottom: "1px solid #676767",
  },
}));

export default function LineItems({
  itemsData,
  handleClick,
  setRef,
  setLineItemsData,
  isLineItemsMatched,
  isPoMatched,
  userOrVendor,
}) {
  const { LINEITEMS_NOT_FOUND, AMOUNT_NOT_MATCHED } = TooltipMessages;
  const namesArray = [];
  itemsData?.map((items) => {
    items?.map((element) => {
      if (isEmpty(namesArray)) {
        namesArray.push(element?.name);
      } else {
        if (!namesArray?.includes(element?.name)) {
          namesArray.push(element?.name);
        }
      }
    });
  });

  const [obj, setObj] = useState(() => {
    const initialObj = namesArray?.reduce((accumulator, value) => {
      return { ...accumulator, [value]: [] };
    }, {});
    itemsData?.forEach((items) => {
      let newArray = [];
      items?.forEach((element) => {
        if (element?.value !== "") {
          initialObj[element?.name].push(element?.value);
        } else {
          initialObj[element?.name].push("N/A");
        }
        newArray.push(element?.name);
      });
      let diff = findMissingUtil(namesArray, newArray);
      if (!isEmpty(diff)) {
        diff.forEach((element) => {
          if (!initialObj[element].includes("")) {
            initialObj[element].push("N/A");
          }
        });
      }
    });
    return initialObj;
  });

  let size = Object.keys(obj).map((items) => {
    return obj[items] && obj[items].length;
  });

  let newArr = new Array(size[0]).fill(0);
  const handleFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      newObj[fieldName][index] = value;
      return newObj;
    });
    setLineItemsData(obj);
  };

  return (
    <Grid
      container
      xs={12}
      sx={{
        backgroundColor: Colors.Canvas_Left_BG,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1rem",
        height: "max-content",
        zIndex: 100,
      }}
    >
      <Grid
        xs={11}
        sx={{
          borderRight: "1px solid gray",
          borderRadius: "5px !important",
          marginTop: ".5rem",
          marginBottom: "1rem",
          height: "163px",
          overflowX: "hidden",
          overflowY: itemsData?.length > 2 ? "scroll" : "",
          "&::-webkit-scrollbar": {
            width: "0.7em",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            outline: "1px solid slategrey",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#fff",
            outline: "1px solid slategrey",
          },
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            border: "1px solid #676767",
            borderRadius: "5px !important",
          }}
        >
          <Table
            sx={{
              minWidth: 700,
              backgroundColor: Colors.Canvas_Left_BG,
              color: "white !important",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {namesArray?.map((element, i) => (
                  <StyledTableCell>
                    <span
                      key={i}
                      style={{
                        borderLeft: "2px solid #676767",
                        paddingLeft: ".5rem",
                        whiteSpace: "nowrap",
                        cursor: "default",
                      }}
                    >
                      {element}
                    </span>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {newArr?.map((x, index) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {namesArray?.map((items, idx) => {
                    let isMatched;
                    let isAmountMatched;
                    let isDescriptionMatched;
                    isPoMatched
                      ? ((isMatched = isLineItemsMatched[index]?.match),
                        (isAmountMatched =
                          isLineItemsMatched[index]?.data?.amount),
                        (isDescriptionMatched =
                          isLineItemsMatched[index]?.data?.description))
                      : "";
                    return (
                      <StyledTableCell
                        sx={{
                          width:
                            items === "Description"
                              ? "max-content"
                              : items === "Amount"
                              ? "150px"
                              : "",
                        }}
                        ref={setRef(
                          "button_" + obj[items][index] + "**%**" + index + idx
                        )}
                        onMouseEnter={() =>
                          handleClick(
                            obj[items][index] + "**%**" + index + idx,
                            idx,
                            itemsData[index]
                          )
                        }
                        onMouseLeave={() =>
                          handleClick("", idx, itemsData[index])
                        }
                      >
                        <div style={{ display: "flex" }}>
                          <input
                            style={{
                              border: "none",
                              paddingLeft: ".5rem",
                              whiteSpace: "nowrap",
                              color: "#fff",
                              backgroundColor: "transparent",
                              outline: "none",
                              padding: "0px !important",
                              width: "100%",
                            }}
                            value={obj[items][index]}
                            onChange={(e) => handleFieldChange(e, index, items)}
                            readOnly={obj[items][index] === "N/A"}
                          />
                          {!isMatched &&
                          isPoMatched &&
                          userOrVendor === "app" ? (
                            items === "Description" && !isDescriptionMatched ? (
                              <Tooltip title={LINEITEMS_NOT_FOUND}>
                                <Error
                                  sx={{
                                    fontSize: "20px",
                                    color: "#E95050",
                                  }}
                                />
                              </Tooltip>
                            ) : items === "Amount" &&
                              isDescriptionMatched &&
                              !isAmountMatched &&
                              userOrVendor === "app" ? (
                              <Tooltip title={AMOUNT_NOT_MATCHED}>
                                <Error
                                  sx={{
                                    fontSize: "20px",
                                    color: "#E4A11B",
                                  }}
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

/* eslint-disable no-useless-concat */
import { baseUrl, NETWORK_ERROR } from "../constants/appConstants";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import { CloseOutlined, PanToolOutlined } from "@mui/icons-material";
import { styled } from "@mui/material";

import { Colors } from "../config/default";

const BASE_URL = baseUrl();
function buildApiEndpoint(path) {
  const url = new URL(path, BASE_URL);
  return url.href;
}

export const request = async (
  path,
  method = "GET",
  body = {},
  contentType = "application/json"
) => {
  const fetchOption = {
    method,

    headers: new Headers({
      Accept: "application/json",
      "Content-Type": contentType,
    }),
    ...(method !== "GET" ? { body: JSON.stringify(body) } : {}),
  };
  try {
    const res = await fetch(buildApiEndpoint(path), fetchOption);

    if (res.status === 200) {
      const jsonResponse = await res.json();
      return jsonResponse;
    } else {
      return res;
    }
  } catch (err) {
    alert(NETWORK_ERROR);
    throw new Error(err);
  }
};
export const setHeaders = () => {
  const headerConfig = {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  };
  return headerConfig;
};
export const setERPType = () => {
  const headerConfig = {
    headers: { ErpType: localStorage.getItem("erpType") },
  };
  return headerConfig;
};

export const formatLineItems = (lineItem) => {
  const formatted = lineItem?.map((item) => {
    const amount = parseFloat(item?.find((e) => e.name === "Amount")?.value);
    const description = item?.find((e) => e.name === "Description")?.value;
    return { amount, description };
  });
  return formatted;
};

export const arrangedLineitems = (lineItem) => {
  const arrangedLineitemsArray = lineItem?.map((subArray) => {
    const descriptionObj = subArray?.find((obj) => obj?.name === "Description");
    const amountObj = subArray?.find((obj) => obj?.name === "Amount");
    const unitPriceObj = subArray?.find((obj) => obj?.name === "Unit_Price");
    const quantityObj = subArray?.find((obj) => obj?.name === "Quantity");
    return [descriptionObj, amountObj, unitPriceObj, quantityObj];
  });
  return arrangedLineitemsArray;
};
export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
  },
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "7px",
    position: "relative",

    paddingLeft: "1em",
    "&:focus": {
      borderRadius: "7px",
      borderColor: "none !important",
    },
  },
}));

export const buttonArray = [
  {
    name: "Reject",
    status: "Reject",
    icon: (
      <CloseOutlined
        sx={{ width: { xs: "13px", lg: "21px" }, color: Colors.RED }}
      />
    ),
    Color: Colors.RED,
  },

  {
    name: "On Hold",
    status: "on Hold",
    icon: (
      <PanToolOutlined
        sx={{ width: { xs: "13px", lg: "17px" }, color: Colors.YELLOW }}
      />
    ),
    Color: Colors.YELLOW,
  },
];

export const drawLine = (
  buttonPosition,
  boxPosition,
  idx,
  dataArray,
  imageDimensions
  // zoomFactor
) => {
  const canvasBorder = document.getElementById("myCanvas");
  const highlights = canvasBorder.getContext("2d");
  const leftSideBarSpace = 450;
  highlights.clearRect(0, 0, canvasBorder.width, canvasBorder.height);
  highlights.beginPath();

  if (dataArray[idx]?.value === "-") return;
  highlights.strokeStyle = Colors.RED; //line
  highlights.lineWidth = 2;
  highlights.moveTo(
    leftSideBarSpace,
    buttonPosition?.top + buttonPosition?.height / 2
  );
  highlights.quadraticCurveTo(
    boxPosition?.left - 100,
    buttonPosition?.top / 2,
    boxPosition?.left,
    boxPosition?.top
  );
  highlights.stroke();
  highlights.strokeRect(
    boxPosition?.left,
    boxPosition?.top,
    dataArray[idx]?.width * imageDimensions?.width,
    dataArray[idx]?.height * imageDimensions?.height
  );
};
export const getPosition = (id, getRef) => {
  // get on-screen position of highlighted boxes
  const reference = getRef(id);
  const object = reference?.current?.getClientRects()[0];
  if (object) {
    const scrollOffset = window.scrollY; // Get the vertical scroll offset
    const position = {
      width: object.width,
      height: object.height,
      left: object.left,
      top: object.top + scrollOffset,
    };
    return position;
  } else {
    return null;
  }
};

export const filterDocuments = (queue) => {
  const newQueue = queue?.filter(
    (doc) =>
      doc.documentStatus === "Populated" ||
      doc.documentStatus === "on Hold" ||
      doc.documentStatus === "Reject" ||
      (doc.documentStatus === "Approved" && doc.erpQbdStatus === "Error")
  );
  return newQueue;
};

export const convertTime = (inputTime) => {
  const match = inputTime?.match(/(\d+)h?:?(\d*)min?:?(\d*)sec?/);
  if (!match) {
    return "Invalid input format";
  }
  const [, hours, minutes, seconds] = match.map((part) =>
    part === "" ? 0 : Number(part)
  );
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  const resultHours = Math.floor(totalSeconds / 3600);
  const resultMinutes = Math.floor((totalSeconds % 3600) / 60);
  const resultSeconds = totalSeconds % 60;
  let result = "";
  if (resultHours > 0) {
    result += `${resultHours}h`;
  }
  if (resultMinutes > 0) {
    result += ` ${resultMinutes}min`;
  }
  if (resultSeconds > 0) {
    result += ` ${resultSeconds}sec`;
  }
  return result;
};

import React from "react";

import { Card, Typography } from "@mui/material";

import DuplicateIcon from "../assets/duplicate.png";
import { Colors } from "../config/default";
import { duplicateDocument } from "../constants/appConstants";

export default function DuplicateDocumentPage() {
  const { DUPLICATE_INFORMATION, DUPLICATE } = duplicateDocument;
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.BG_BLACK,
      }}
    >
      <Card
        sx={{
          width: { xs: "70vw", md: "50vw" },
          backgroundColor: Colors.NAV_BLACK,
          borderRadius: "16px",
          textAlign: "center",
          padding: "2em",
        }}
      >
        <img
          style={{ marginTop: "2em", height: "15em" }}
          src={DuplicateIcon}
          alt=""
        />
        <Typography sx={{ color: Colors.WHITE, fontSize: "1.5rem", mt: "1em" }}>
          {DUPLICATE}
        </Typography>
        <Typography
          sx={{
            color: Colors.TEXTGREY,
            fontSize: "1rem",
            mt: "1em",
            mb: "2em",
            padding: "0em 2em",
          }}
        >
          {DUPLICATE_INFORMATION}
        </Typography>
      </Card>
    </div>
  );
}

import React, { useState } from 'react';

import { Modal, Fade, Grid, IconButton, CircularProgress } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function ViewDocument({ selectedImage, imageLoading, open, setOpen }) {
  const [index, setIndex] = useState(0);

  const slideRight = () => {
    if (index === selectedImage?.length - 1) {
      setIndex(0);
    } else {
      setIndex((index + 1) % selectedImage?.length);
    }
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (index === 0) {
      setIndex(selectedImage?.length - 1);
    } else {
      if (nextIndex < 0) {
        setIndex(selectedImage?.length - 1);
      } else {
        setIndex(nextIndex);
      }
    }
  };
  const handleButtonClicked = (event) => {
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpen(false);
    setIndex(0);
  };

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition>
      <Fade in={open}>
        <div onClick={handleClose} style={{ cursor: 'pointer' }}>
          <Grid container>
            {imageLoading ? (
              <Grid
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: { xs: '40%', lg: '50%' }
                }}>
                <CircularProgress size={90} color="primary" />
              </Grid>
            ) : (
              selectedImage?.length > 0 && (
                <>
                  {selectedImage?.length > 1 && (
                    <IconButton
                      sx={{
                        zIndex: 10000,
                        position: 'absolute',
                        cursor: 'pointer',
                        marginRight: '1rem',
                        color: 'white',
                        left: '25%',
                        backgroundColor: '#13293E',
                        top: { xs: '90%', lg: '50%' },
                        '&:hover': {
                          background: '#13293E'
                        },
                        '&:disabled': {
                          background: '#13293E',
                          color: 'white',
                          opacity: '0.6',
                          cursor: 'not-allowed'
                        }
                      }}
                      onClick={(event) => {
                        handleButtonClicked(event);
                        slideLeft();
                      }}>
                      <ArrowBackIosNewIcon />
                    </IconButton>
                  )}
                  <div className={`image-container enlarged`}>
                    <img id="i" src={selectedImage[index]} alt={index} className="image" />
                  </div>
                  {selectedImage?.length > 1 && (
                    <IconButton
                      sx={{
                        cursor: 'pointer',
                        color: 'white',
                        zIndex: 10000,
                        position: 'absolute',
                        top: { xs: '90%', lg: '50%' },
                        right: '25%',
                        backgroundColor: '#13293E',
                        '&:hover': {
                          background: '#13293E'
                        },
                        '&:disabled': {
                          background: '#13293E',
                          color: 'white',
                          opacity: '0.6',
                          cursor: 'not-allowed'
                        }
                      }}
                      onClick={(event) => {
                        handleButtonClicked(event);
                        slideRight();
                      }}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  )}
                </>
              )
            )}
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}

export default ViewDocument;

import Env from "../envConfig/environment.json";
export const Environment = Env.Environment;
export const baseUrl = () => {
  let BASE_URL = "";
  if (Environment === "Development") {
    BASE_URL = "https://dev-api-v2.beinghumans.co/";
  } else if (Environment === "Production") {
    BASE_URL = "https://api-v2.beiinghuman.com/";
  } else {
    BASE_URL = "http://localhost:3000/";
  }
  return BASE_URL;
};

export const NETWORK_ERROR = "NETWORK_ERROR";

export const ValidationMessages = {
  REQUIRED: "This field is required",
  INVALID_EMAIL: "Invalid email address",
  INVALID_USERNAME: "User name cannot contain special characters",
  PASSWORD_CHECK:
    "At least 8 characters, including uppercase, lowercase, number and special character (except #)",
  INVALID_AMOUNT: "Amount is invalid. It must only contain digits",
  INVALID_PAYMENT:
    "CURRENT PAYMENT DUE is invalid. It must only contain digits",
  INVALID_RETAINAGE:
    "RETAINAGE PERCENTAGE is invalid. It must only contain digits",
  INVALID_DATE: "Invoice Date is invalid, It must be in mm/dd/yyyy format",
  INVALID_G702_DATE: "PERIOD TO is invalid, It must be in mm/dd/yyyy format",
  INVALID_PASSWORD: "Email Address or Password is incorrect",
  INVALID_VENDOR: "Vendor name must not be empty",
  INVALID_VENDOR_LENGTH: "Vendor must not be grearter than 41 characters",
  INVALID_INVOICENUMBER: "Invoice number must not be empty",
  INVALID_INVOICELENGTH:
    "Invoice number must not be grearter than 20 characters",
  INVALID_APPLICATION_LENGTH:
    "Application number must not be grearter than 20 characters",
  INVALID_PROJECTNUMBER: "Application number must not be empty",
  INVALID_CONTRACTOR: "From contractor must not be empty",
  INVALID_CONTRACTOR_LENGTH:
    "From contractor must not be grearter than 41 characters",
  FORGOT_PASSWORD: "Forgot Password ?",
  CREATE_PASSWORD: "Create new password",
  INVALID_GL: "GL Account must not be grearter than 31 characters",
  HEADING: "Forget password Being Humans Account",
  INVALID_TOTAL: "Total Fees is invalid. It must only contain digits",
  INVALID_SUBTOTAL: "Subtotal is invalid. It must only contain digits",
  PASSWORD_SCHEMA:
    "Password should be at least 8 characters, including uppercase, lowercase, number and special character (except #)",
};

export const canvasDetails = {
  DOCS: "Review Document",
  APPROVE: "Approve",
  CANCEL: "Cancel",
  DOC_TYPE: "Document Type",
  GL_ACCOUNT: "GL Account",
  JOB_ID: "Job id",
  COST_ID: "Cost id",
  COST_TYPE: "Cost Type",
  VERIFY: "Verify All",
  MATCH_PO: "Match PO",
};

export const userRoles = {
  ACCOUNTANT: "Accountant",
  ADMIN: "Admin",
  OWNER: "Owner",
  CLEVEL: "C_Level",
  MANAGER: "Manager",
};

export const topNav = {
  DASHBOARD: "Dashboard",
  BACK: "Back",
};

export const TooltipMessages = {
  SPLIT_PDF_MESSAGE:
    "Split multi-page PDFs into separate documents before processing",
  SEND_EMAIL: "Get the processed data in your email.",
  SYNC_DATA: "Fetch updated data from your integrated ERP",
  MANUAL_UPLOAD:
    "Upload files having account information. Such as GL accounts, PO, Vendors etc.",
  MANUAL_DOWNLOAD:
    "Download files having account information. Such as GL accounts, PO, Vendors etc.",
  UPLOAD_FILE_MESSAGE: "Upload documents for processing",
  SEARCH_MESSAGE:
    "You can Search by Vendor, Cost Id, Job Id, Cost Type, Total, Purchase Order, Document Number.",
  LINEITEMS_NOT_FOUND: "Line item does not exist",
  AMOUNT_NOT_MATCHED: "Amount do not match",
  SEE_DUPLICATE: "Review Duplicate Document",
};

export const lineItemsText = {
  SHOW_LINEITEMS: "Show Line Items",
};

export const documentCommentsText = {
  COMMENTS: "Comments",
  EMPTY_COMMENTS: "The comments section is currently empty.",
  IMAGE_NOT_AVAILABLE: "Document Image is not Available",
  ERROR_DOWNLOADING_CSV: "Error downloading the CSV file",
  DOCUMENT_NAME: "Document Name",
  CREATED_AT: "Created At",
  FAILED_REASON: "Failed Reason",
};

export const manualApprovalPage = {
  CUSTOM_APPROVAL: "Custom Approval",
  REQUIRED_APPROVAL: "Requied Approvals",
  SELECT_APPROVER: "Select User from whome you want the Approval",
  SUBMIT: "Submit",
};

export const unauthorizedPage = {
  UNAUTHORIZED: "Unauthorized Access",
  UNAUTHORIZED_INFORMATION:
    "Oops! You do not have permission to view this page. It looks like you might not be authorized to access this content. If you believe this is an error, please check with your administrator or the person in charge.",
};

export const duplicateDocument = {
  DUPLICATE: "Duplicate Document",
  DUPLICATE_INFORMATION:
    "Oops! It appears that there's a duplicate of the document you're attempting to access. If you think this is a mistake, please check with your administrator or the person in charge.",
};

export const dashboardText = {
  AVERAGE_TIME: "Average approval time for each document",
  TIME: "AVG Time:",
  MINUTES: "Minutes",
  AVERAGE_CHANGES: "Average changes per document",
  CHANGES: "AVG Changes:",
  CHANGE: "Changes",
  VENDOR_DOCUMENTS: "Documents by  Vendor",
  AMOUNT_DOCUMENTS: "Documents by  Amount",
  DOCUMENT_STATUS_RATIO: "Documents by Error Ratio and Success Ratio",
  DOC_TYPE: "Documents by Type",
  RESPONSE_TIME: "Response Time by Each Document",
  RECORD_NOT_FOUND: "No records were found associated with this filter.",
};

import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Gravatar from "react-gravatar";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  AppBar,
  Grid,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Tooltip,
  MenuItem,
  Hidden,
  styled,
  Button,
} from "@mui/material";
import { KeyboardArrowDown, ArrowBack } from "@mui/icons-material";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";
import logo from "../../src/assets/fullLogo.svg";
import smallLogo from "../../src/assets/BLogo.svg";

import TabPanel from "./tabs";
import { Colors } from "../config/default";
import { userRoles, topNav } from "../constants/appConstants";
import DocumentComments from "./documentComments";
import EmailVendor from "./emailVendor";
import { get_tabs } from "../redux/actions/action";
import { HtmlTooltip } from "../services/common";

const settings = ["Profile", "Users", "Logout"];
const menuSeetings = ["Profile", "Logout"];
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "10px",
    backgroundColor: "#282828",
    marginTop: theme.spacing(3),
    border: ".3px solid #1E1E1E",
    minWidth: 160,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: ".5em",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: "8px",
      },
      "&:hover": {
        backgroundColor: Colors.NAV_BLACK,
        borderRadius: "8px",
      },
    },
  },
}));
function TopNav({
  username,
  activeTab,
  setActiveTab,
  currentTabs,
  path,
  userRole,
  filteredData,
  recordExist,
  handleClear,
  show,
  buttonArray,
  submitData,
  id,
  comment,
  setComment,
  docName,
  padding,
  userOrVendor,
}) {
  const { ADMIN, CLEVEL, OWNER, ACCOUNTANT, MANAGER } = userRoles;
  const smallScreen = useMediaQuery("(max-width:880px)");
  const { BACK } = topNav;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const Tabs = {
    activeTab: userDetails?.role === ADMIN ? "Documents" : "Dashboard",
    usersActiveTab: "All Users",
    documentChildActiveTab: "To Review",
    IntegrationChildActiveTab: "My Integration",
  };
  const menuItemClickHandlers = [
    () => {
      navigate("/userProfile");
    },
    () => {
      navigate("/users");
    },
    () => {
      dispatch(get_tabs({}));
      localStorage.clear();
      navigate("/");
    },
  ];
  const menuItemHandler = [
    () => {
      navigate("/userProfile");
    },

    () => {
      dispatch(get_tabs({}));
      localStorage.clear();
      navigate("/");
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position={path === "canvas" ? "fixed" : "static"}
        sx={{
          boxShadow: "0",
          padding: { xs: "0 0rem", md: padding ? "0 4em 0 0" : "0 4rem" },
          height: "4.2rem",
        }}
      >
        <Container maxWidth="xxl">
          <Toolbar
            disableGutters
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {path === "canvas" ? (
              <Grid item sx={{ display: "flex", alignItems: "center" }}>
                {userOrVendor === "app" && (
                  <Button
                    variant="outlined"
                    sx={{
                      border: `1px solid ${Colors.DARKBLUE}`,
                      borderRadius: "10px",
                      height: "2rem",
                      width: "6rem",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: ".8em",
                      textTransform: "none",
                      color: Colors.WHITE,
                      "&:hover": {
                        background: "none",
                        border: `1px solid ${Colors.DARKBLUE}`,
                      },
                    }}
                    onClick={() => {
                      if (
                        userDetails?.role === CLEVEL ||
                        userDetails?.role === MANAGER
                      ) {
                        navigate("/approver");
                      } else if (userDetails?.role === ACCOUNTANT) {
                        navigate("/accountant");
                      } else if (userDetails?.role === ADMIN) {
                        navigate("/admin");
                      } else {
                        navigate("/home");
                      }
                    }}
                  >
                    <ArrowBack
                      sx={{
                        color: Colors.DARKBLUE,
                        fontSize: "1.4em",
                        mr: ".3em",
                      }}
                    />
                    {BACK}
                  </Button>
                )}
                <HtmlTooltip
                  title={
                    <Typography color="inherit" sx={{ fontSize: "1em" }}>
                      {docName}
                    </Typography>
                  }
                >
                  <Typography
                    sx={{
                      ml: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    {docName.length > 15
                      ? `${docName.substring(0, 12)}...${docName.substring(
                          docName.lastIndexOf(".") - 1
                        )}`
                      : docName}{" "}
                  </Typography>
                </HtmlTooltip>
              </Grid>
            ) : (
              <>
                <Hidden mdDown>
                  <img
                    src={logo}
                    alt="beingHumans"
                    style={{ height: "2em", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(get_tabs(Tabs));
                      userDetails?.role === ADMIN
                        ? navigate("/admin")
                        : userDetails?.role === CLEVEL ||
                          userDetails?.role === MANAGER
                        ? navigate("/approver")
                        : userDetails?.role === ACCOUNTANT
                        ? navigate("/accountant")
                        : navigate("/home");
                    }}
                  />
                </Hidden>
                <Hidden mdUp>
                  <img
                    src={smallLogo}
                    alt="beingHumans"
                    style={{ height: "2em", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(get_tabs(Tabs));
                      userDetails?.role === ADMIN
                        ? navigate("/admin")
                        : userDetails?.role === CLEVEL ||
                          userDetails?.role === MANAGER
                        ? navigate("/approver")
                        : userDetails?.role === ACCOUNTANT
                        ? navigate("/accountant")
                        : navigate("/home");
                    }}
                  />
                </Hidden>
              </>
            )}

            {path === "canvas" || smallScreen ? (
              <Grid sx={{ height: { xs: "", md: "4.5rem" } }}></Grid>
            ) : (
              <TabPanel
                tabs={currentTabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                filteredData={filteredData}
                recordExist={recordExist}
                handleClear={handleClear}
                tab="parent"
                path={path}
              />
            )}

            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              {path === "canvas" && userOrVendor === "app" && (
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  {userDetails?.role === OWNER ||
                  userDetails?.role === ACCOUNTANT
                    ? ""
                    : buttonArray?.map((btn) => {
                        return (
                          <Button
                            variant="outlined"
                            sx={{
                              border: `1px solid ${btn?.Color}`,
                              borderRadius: "10px",
                              height: "2rem",
                              width: "7rem",
                              marginRight: "1rem",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: ".8em",
                              textTransform: "none",
                              color: Colors.WHITE,
                              "&:hover": {
                                background: "none",
                                border: `1px solid ${btn?.Color}`,
                              },
                            }}
                            startIcon={btn?.icon}
                            onClick={(e) => submitData(e, btn?.status)}
                          >
                            {btn?.name}
                          </Button>
                        );
                      })}
                  <EmailVendor />
                  <DocumentComments
                    id={id}
                    comment={comment}
                    setComment={setComment}
                  />
                </Grid>
              )}
              {path === "canvas" && userOrVendor === "api" ? (
                <>
                  <Hidden mdDown>
                    <img
                      src={logo}
                      alt="beingHumans"
                      style={{ height: "2em" }}
                    />
                  </Hidden>
                  <Hidden mdUp>
                    <img
                      src={smallLogo}
                      alt="beingHumans"
                      style={{ height: "2em" }}
                    />
                  </Hidden>
                </>
              ) : (
                <>
                  {path === "dashboard" ? (
                    ""
                  ) : (
                    <>
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Gravatar
                          email={userDetails?.user?.email}
                          style={{
                            order: "2px solid #1AACAC",
                            height: "2em",
                            width: "2em",
                            borderRadius: "50%",
                          }}
                        />
                        <StyledMenu
                          id="menu-appbar"
                          anchorEl={anchorElUser}
                          open={Boolean(anchorElUser)}
                          onClose={handleCloseUserMenu}
                        >
                          {path === "owner" || show === "showUser"
                            ? settings.map((setting, index) => (
                                <MenuItem
                                  sx={{ width: "150px", borderRadius: "10px" }}
                                  key={setting}
                                  onClick={() => {
                                    menuItemClickHandlers[index]();
                                  }}
                                >
                                  <Typography textAlign="center">
                                    {setting}
                                  </Typography>
                                </MenuItem>
                              ))
                            : menuSeetings.map((setting, index) => (
                                <MenuItem
                                  sx={{ width: "150px", borderRadius: "10px" }}
                                  key={setting}
                                  onClick={() => {
                                    menuItemHandler[index]();
                                  }}
                                >
                                  <Typography textAlign="center">
                                    {setting}
                                  </Typography>
                                </MenuItem>
                              ))}
                        </StyledMenu>
                      </Box>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          marginX: ".5rem",
                          alignItems: "flex-start",
                          color: "secndary.light",
                        }}
                      >
                        <Grid item sx={{ width: "max-content" }}>
                          <Typography sx={{ fontSize: ".9em" }}>
                            {username}
                          </Typography>
                          <Typography
                            sx={{ fontSize: ".7em", color: Colors.TEXTGREY }}
                          >
                            {userRole === OWNER
                              ? "AP Person"
                              : userDetails?.user?.name}
                          </Typography>
                        </Grid>
                        <Tooltip title="Open settings">
                          <IconButton
                            sx={{ p: 0, color: "secndary.light" }}
                            onClick={handleOpenUserMenu}
                          >
                            <KeyboardArrowDown />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
      {smallScreen &&
        (path === "canvas" ? (
          ""
        ) : (
          <AppBar
            position="static"
            sx={{
              boxShadow: "0",
              height: "4.2rem",
              backgroundColor: Colors.NAV_BLACK,
            }}
          >
            <Container maxWidth="xxl">
              <Toolbar
                disableGutters
                sx={{
                  padding: "0em",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <TabPanel
                  tabs={currentTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  filteredData={filteredData}
                  recordExist={recordExist}
                  handleClear={handleClear}
                  tab="parent"
                  path={path}
                />
              </Toolbar>
            </Container>
          </AppBar>
        ))}
    </ThemeProvider>
  );
}
export default TopNav;

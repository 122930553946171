/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import { Grid, Typography, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import { Colors } from '../config/default';

function LoadNextAndPrevDoc({
  id,
  setCurrentId,
  setImageDimensions,
  setButtonColors,
  setTypeCheck,
  setGlCheck,
  setCostTypeCheck,
  setCostCheck,
  setJobCheck,
  setImages
}) {
  const queue = useSelector((state) => state?.pending?.pending[0]);
  const documents = queue?.filter(
    (doc) =>
      doc.documentStatus === 'Populated' ||
      doc.documentStatus === 'on Hold' ||
      doc.documentStatus === 'Reject' ||
      (doc.documentStatus === 'Approved' && doc.erpQbdStatus === 'Error')
  );
  const currentDocumentId = id;
  const navigate = useNavigate();
  const currentIndex = documents.findIndex((doc) => doc._id === currentDocumentId);

  const loadNextDocument = () => {
    if (documents && documents.length > 0) {
      const nextIndex = (currentIndex + 1) % documents.length;
      const nextDocumentId = documents[nextIndex]._id;
      setImageDimensions({});
      setImages([]);
      setButtonColors([]);
      setTypeCheck(false);
      setGlCheck(false);
      setCostTypeCheck(false);
      setJobCheck(false);
      setCostCheck(false);
      setCurrentId(nextDocumentId);
      navigate(`/canvas/${nextDocumentId}`);
    }
  };

  const loadPrevDocument = () => {
    if (documents && documents.length > 0) {
      const prevIndex = (currentIndex - 1 + documents.length) % documents.length;
      const prevDocumentId = documents[prevIndex]._id;
      setImageDimensions({});
      setImages([]);
      setButtonColors([]);
      setTypeCheck(false);
      setGlCheck(false);
      setCostTypeCheck(false);
      setJobCheck(false);
      setCostCheck(false);
      setCurrentId(prevDocumentId);
      navigate(`/canvas/${prevDocumentId}`);
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      const isInputField = document.activeElement.tagName === 'INPUT';
      if (isInputField) {
        return;
      }
      if (event.key === 'ArrowRight') {
        loadNextDocument();
      } else if (event.key === 'ArrowLeft') {
        loadPrevDocument();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [documents, currentIndex, loadNextDocument, loadPrevDocument]);
  return (
    <Grid xs={12} sx={{ position: 'absolute', right: '20px', bottom: '90px', zIndex: '100' }}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5em' }}>
        <Grid
          container
          sx={{
            height: '2em',
            width: '2em',
            background: '#373735',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0.2em',
            '&:hover': {
              background: currentIndex === 0 ? '' : '#13293E'
            },
            '&:disabled': {
              background: '#13293E',
              color: 'white',
              opacity: '0.6',
              cursor: 'not-allowed'
            }
          }}>
          <IconButton disabled={currentIndex === 0} onClick={() => loadPrevDocument()}>
            <ArrowBackIosNewIcon sx={{ color: Colors.WHITE, height: '.6em', width: '.6em' }} />
          </IconButton>
        </Grid>

        <Grid>
          <Typography
            variant="h6"
            sx={{ textAlign: 'center', fontSize: '1em', color: Colors.WHITE }}>
            Document {currentIndex + 1} of {documents?.length}
          </Typography>
        </Grid>

        <Grid
          disabled={currentIndex === documents?.length - 1}
          container
          sx={{
            height: '2em',
            width: '2em',
            background: '#373735',
            borderRadius: '0.2em',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              background: currentIndex === documents?.length - 1 ? '' : '#13293E'
            },
            '&:disabled': {
              background: '#13293E',
              color: 'white',
              opacity: '0.6',
              cursor: 'not-allowed'
            }
          }}>
          <IconButton
            disabled={currentIndex === documents?.length - 1}
            onClick={() => loadNextDocument()}>
            <ArrowForwardIosIcon
              sx={{
                color: Colors.WHITE,
                height: '.6em',
                width: '.6em'
              }}
            />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LoadNextAndPrevDoc;

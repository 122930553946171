import { isEmpty, isNaN } from 'lodash';
import { ValidationMessages } from '../constants/appConstants';

const validateTotal = (value) => {
  const digits = value?.match(/^[\d|.|,| ]+/);
  if (digits && digits[0] === value) {
    return true;
  }
  return false;
};

const validateDate = (datum) => {
  const newDate = new Date(datum);
  if (isNaN(newDate?.getDate())) {
    return false;
  }
  if (isNaN(newDate?.getMonth())) {
    return false;
  }
  if (isNaN(newDate?.getFullYear())) {
    return false;
  }
  return true;
};

export const ValidateAnnotation = (data) => {
  let res = {};
  let isTotalValid = false;
  let isDateValid = false;
  let isVendorValid = false;
  let isInvoiceNoValid = true;

  data?.forEach((datum) => {
    const { name, value } = datum;
    if (name === 'total') {
      if (validateTotal(value)) {
        isTotalValid = true;
      } else {
        res['message'] = ValidationMessages.INVALID_AMOUNT;
      }
    }
    if (name === 'invoice_date') {
      if (validateDate(value)) {
        isDateValid = true;
      } else {
        res['message'] = ValidationMessages.INVALID_DATE;
      }
    }
    if (name === 'vendor') {
      if (!isEmpty(value) && value !== '-' && value?.length <= 41) {
        isVendorValid = true;
      } else {
        if (value?.length > 41) {
          res['message'] = ValidationMessages.INVALID_VENDOR_LENGTH;
        } else {
          res['message'] = ValidationMessages.INVALID_VENDOR;
        }
      }
    }
    if (name === 'invoice_number') {
      if (!isEmpty(value) && value !== '-' && value?.length <= 20) {
        isInvoiceNoValid = true;
      } else {
        if (value?.length > 20) {
          res['message'] = ValidationMessages.INVALID_INVOICELENGTH;
        } else {
          res['message'] = ValidationMessages.INVALID_INVOICENUMBER;
        }
      }
    }
  });

  if (isTotalValid && isDateValid && isVendorValid && isInvoiceNoValid) {
    res['status'] = true;
  } else {
    res['status'] = false;
  }

  return res;
};

export const autoCap = (value) => {
  const spiltVal = value?.split('_');
  for (let i = 0; i <= spiltVal?.length; i++) {
    if (spiltVal[i] === undefined) {
      continue;
    } else {
      spiltVal[i] = spiltVal[i][0]?.toUpperCase() + spiltVal[i]?.substr(1);
    }
  }
  return spiltVal?.join(' ');
};

export const validateG702 = (data) => {
  let res = {};
  let isTotalValid = false;
  let isDateValid = false;
  let isRetainageValid = false;
  let isProjectNoValid = false;
  let isVendorValid = false;
  data?.forEach((datum) => {
    const { name, value } = datum;
    if (name === 'current_payment_due') {
      if (validateTotal(value)) {
        isTotalValid = true;
      } else {
        res['message'] = ValidationMessages.INVALID_PAYMENT;
      }
    }
    if (name === 'from_contractor') {
      if (!isEmpty(value) && value !== '-' && value?.length <= 41) {
        isVendorValid = true;
      } else {
        if (value?.length > 41) {
          res['message'] = ValidationMessages.INVALID_CONTRACTOR_LENGTH;
        } else {
          res['message'] = ValidationMessages.INVALID_CONTRACTOR;
        }
      }
    }
    if (name === 'retainage_percentage') {
      if (validateTotal(value)) {
        isRetainageValid = true;
      } else {
        res['message'] = ValidationMessages.INVALID_RETAINAGE;
      }
    }
    if (name === 'period_to') {
      if (validateDate(value)) {
        isDateValid = true;
      } else {
        res['message'] = ValidationMessages.INVALID_G702_DATE;
      }
    }
    if (name === 'application_no') {
      if (!isEmpty(value) && value !== '-' && value?.length <= 20) {
        isProjectNoValid = true;
      } else {
        if (value?.length > 20) {
          res['message'] = ValidationMessages.INVALID_APPLICATION_LENGTH;
        } else {
          res['message'] = ValidationMessages.INVALID_PROJECTNUMBER;
        }
      }
    }
  });
  if (isTotalValid && isDateValid && isRetainageValid && isProjectNoValid && isVendorValid) {
    res['status'] = true;
  } else {
    res['status'] = false;
  }

  return res;
};
export const ValidateGlAccount = (value) => {
  let res = {};
  if (value?.length <= 31) {
    res['status'] = true;
  } else {
    res['message'] = ValidationMessages.INVALID_GL;
    res['status'] = false;
  }

  return res;
};

import { Route, Routes } from "react-router-dom";

import "./App.css";
import CanvasPage from "./pages/canvasPage";
import UnauthorizedPage from "./pages/unauthorisedPage";
import LandingPage from "./pages/landingPage";
import DuplicateDocumentPage from "./pages/duplicateDocumentPage";
import MainDashboard from "./pages/mainDashboard";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/:id" element={<CanvasPage />} />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
      <Route path="/dashboard/:token" element={<MainDashboard />} />
      <Route
        path="/duplicateDocumentPage"
        element={<DuplicateDocumentPage />}
      />
    </Routes>
  );
}

export default App;

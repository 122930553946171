/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function DateRangePickerWithButtons({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  function onChangeHandler(value) {
    setStartDate(value[0]);
    setEndDate(value[1]);
  }
  const handleLast7DaysClick = () => {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 6);
    setStartDate(last7Days);
    setEndDate(today);
  };
  useEffect(() => {
    handleLast7DaysClick();
  }, []);
  return (
    <div>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={onChangeHandler}
        dateFormat="dd MMM yyyy"
        className={"form-control form-control-sm"}
        showDisabledMonthNavigation
        maxDate={new Date()}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    </div>
  );
}
export default DateRangePickerWithButtons;

export const get_queue = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'queueData',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const get_pending_data = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'pendingData',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const user_information = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'userInfo',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

export const get_tabs = (text) => async (dispatch) => {
  try {
    dispatch({
      type: 'tabs',
      payload: text
    });
  } catch (err) {
    return err;
  }
};

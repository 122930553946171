/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Card, Grid, Typography, CircularProgress } from "@mui/material";

import {
  CheckCircleOutlined,
  InsertDriveFileOutlined,
  ErrorOutlineOutlined,
} from "@mui/icons-material";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Colors } from "../config/default";
import { getDashboardRecord } from "../services/services";
import { dashboardText } from "../constants/appConstants";
import LineGraph from "../components/lineGraph";
import PieChartCard from "../components/pieChart";

function Dashboard({ startDate, endDate }) {
  const {
    AVERAGE_CHANGES,
    CHANGES,
    CHANGE,
    DOCUMENT_STATUS_RATIO,
    RESPONSE_TIME,
    DOC_TYPE,
  } = dashboardText;
  const [resdata, setResData] = useState(null);
  const [loading, setLoading] = useState(false);
  const url = window.location.href;
  const urlParams = new URL(url);
  const { token } = useParams();
  localStorage.setItem("token", token);
  const userOrVendor = urlParams.searchParams.get("callSource")?.toLowerCase();
  const navigate = useNavigate();
  useEffect(() => {
    if (userOrVendor !== "api") {
      navigate("/unauthorized");
    }
  }, [userOrVendor]);
  const getData = async (start, end) => {
    setLoading(true);
    if (start && end && token) {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
      const utcStartDate = new Date(
        start.getTime() - start.getTimezoneOffset() * 60000
      );
      const utcEndDate = new Date(
        end.getTime() - end.getTimezoneOffset() * 60000
      );
      const result = await getDashboardRecord(
        utcStartDate?.toISOString(),
        utcEndDate?.toISOString(),
        userOrVendor
      );
      if (result.status === 200) {
        setResData(result?.data?.payload?.data);
      } else if (result?.response?.status === 401) {
        localStorage.clear();
        navigate("/");
      } else {
        navigate("/unauthorized");
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    getData(startDate, endDate);
  }, [startDate, endDate, token]);

  const documentStatusRatio = resdata?.documentStatusRatio?.map(
    (documentStatusPercentage, i) => ({
      id: i,
      value: documentStatusPercentage?.value,
      label: documentStatusPercentage?.label,
    })
  );
  const ResponseTimeEachDocument = resdata?.ResponseTimeEachDocument?.map(
    (timeTaken, i) => ({
      id: i,
      value: timeTaken?.value,
      label: timeTaken?.label,
    })
  );

  const documentType = resdata?.documentType?.map((docType, i) => ({
    id: i,
    value: docType?.value,
    label: docType?.label,
  }));

  const documents = [
    {
      status: "Error",
      value: resdata?.documentsCount?.Error || 0,
      icon: (
        <ErrorOutlineOutlined sx={{ color: Colors.RED, marginRight: ".5em" }} />
      ),
      color: Colors.RED,
    },
    {
      status: "Success",
      value: resdata?.documentsCount?.Success || 0,
      icon: (
        <CheckCircleOutlined
          sx={{ color: Colors.LIGHTBLUE, marginRight: ".5em" }}
        />
      ),
      color: Colors.LIGHTBLUE,
    },

    {
      status: "Total",
      value: resdata?.documentsCount?.totalDocuments || 0,
      icon: (
        <InsertDriveFileOutlined
          sx={{ color: Colors.YELLOW, marginRight: ".5em" }}
        />
      ),
      color: Colors.YELLOW,
    },
  ];
  return (
    <ThemeProvider theme={theme}>
      {loading || resdata === null ? (
        <Grid
          container
          sx={{
            height: "70vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={150} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid
            container
            sx={{
              justifyContent: "center",
              marginTop: ".7rem",
              height: { xs: "59vh", sm: "75vh" },
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: ".2em",
                height: "0em",
                borderRadius: "20px",
              },
              "&::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 6px rgba(0,0,0,0.11)",
                webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.11)",
                outline: "1px solid slategrey",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#fff",
                outline: "1px solid slategrey",
              },
            }}
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                paddingX: "2rem",
                marginTop: { xs: "1.25rem", sm: ".3rem" },
                flexWrap: "wrap",
                gap: { xs: "1em", lg: "2em" },
              }}
            >
              {documents?.map((document, i) => {
                return (
                  <Card
                    key={i}
                    sx={{
                      border: `2px solid ${document?.color}`,
                      borderRadius: "20px",
                      width: { xs: "100%", sm: "40%", lg: "13.35vw" },
                      height: "4.5rem",
                      padding: "1rem",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {document?.icon}
                      <Typography
                        sx={{ fontSize: "1rem", color: document?.color }}
                      >
                        {document?.status}
                      </Typography>
                    </div>
                    <Typography sx={{ fontSize: "2rem" }}>
                      {document?.value}
                    </Typography>
                  </Card>
                );
              })}
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: "space-between",
                marginTop: ".6rem",
                gap: { xs: "1em", sm: "1em", md: "0" },
                paddingX: "2rem",
              }}
            >
              <LineGraph
                average={AVERAGE_CHANGES}
                averageVal={
                  resdata?.documentActivityChanges?.avgActivityChanges
                }
                averageHeading={CHANGES}
                target={CHANGE}
                dataSet={resdata?.documentActivityChanges?.averageTimeByDate}
                xAxisType="date"
                xAxisKey="date"
                yAxisKey="averageChanges"
                dataKey="averageChanges"
              />
              <PieChartCard text={DOC_TYPE} dataArray={documentType} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                justifyContent: { xs: "space-between", xl: "flex-start" },
                paddingX: "2rem",
                gap: { xl: "3.3%" },
              }}
            >
              <PieChartCard
                text={DOCUMENT_STATUS_RATIO}
                dataArray={documentStatusRatio}
              />
              <PieChartCard
                text={RESPONSE_TIME}
                dataArray={ResponseTimeEachDocument}
                showAverage={true}
                description="Average Time"
                averageVal={resdata?.totalAvg}
              />
            </Grid>
          </Grid>
        </>
      )}
    </ThemeProvider>
  );
}

export default Dashboard;

import * as React from "react";
import { useSelector } from "react-redux";

import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Drawer, Button, Grid } from "@mui/material";

import LineItems from "./lineItems";
import { canvasDetails } from "../constants/appConstants";
import { Colors } from "../config/default";
import { lineItemsText } from "../constants/appConstants";

export default function BottomDrawer({
  itemsData,
  handleClick,
  setRef,
  setLineItemsData,
  isLineItemsMatched,
  isPoMatched,
  endIcon,
  disabled,
  onClick,
  userOrVendor,
}) {
  const { SHOW_LINEITEMS } = lineItemsText;
  const { MATCH_PO } = canvasDetails;
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        backgroundColor: Colors.Canvas_Left_BG,
      }}
      role="presentation"
    >
      <LineItems
        isPoMatched={isPoMatched}
        itemsData={itemsData}
        handleClick={handleClick}
        setRef={setRef}
        setLineItemsData={setLineItemsData}
        isLineItemsMatched={isLineItemsMatched}
        userOrVendor={userOrVendor}
      />
      {userDetails?.user?.poMatchPermission &&
        userOrVendor ===
          "app"(
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: { xs: ".7rem", md: "3.5rem" },
                marginBottom: "1rem",
              }}
            >
              <Button
                sx={{
                  marginTop: "7px",
                  margin: ".3rem",
                  borderRadius: "5px",
                  color: Colors.DARKBLUE,
                  fontWeight: "bold",
                  fontSize: { xs: "10px", lg: "13px" },
                  "&:hover": {
                    background: "none",
                  },
                  "&:disabled": {
                    color: "gray",
                    cursor: "not-allowed",
                  },
                }}
                endIcon={endIcon}
                disabled={disabled}
                onClick={onClick}
              >
                {MATCH_PO}
              </Button>
            </Grid>
          )}
    </Box>
  );

  return (
    <div>
      {["bottom"]?.map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{
              fontSize: "13px",
              borderRadius: "5px",
              background: "transparent",
              color: "#ffffff",
              float: "right",
            }}
            endIcon={<ArrowRightAltIcon sx={{ fontSize: "15px" }} />}
            onClick={toggleDrawer(anchor, true)}
          >
            {SHOW_LINEITEMS}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}

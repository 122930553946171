export const Colors = {
  BG_BLACK: '#141414',
  DARKBLUE: '#1AACAC',
  MUSTARD: '#EEBA6D',
  WHITE: '#fafafa',
  LIGHTGREY: '#BABABA',
  DEEPBLUE: '#24658D',
  TOURQUISE: '#2E97A7',
  NAV_BLACK: '#1E1E1E',
  TEXTGREY: '#A7A7A7',
  RED: '#E95050',
  DEEPGREY: '#585858',
  Green: '#17B169',
  Canvas_BG: '#282828',
  Canvas_Left_BG: '#161618',
  LIGHTBLUE: '#429EB0',
  YELLOW: '#F1A230',
  GREY: '#262626'
};
